import { InjectionKey } from "vue";

import { TargetSegment } from "@/api/segments/typedefs";
import { ReadonlyRef } from "@/lib/typing";

export const segmentsKey = Symbol() as InjectionKey<
  ReadonlyRef<TargetSegment[]>
>;
export const productKey = Symbol() as InjectionKey<ReadonlyRef<number>>;
export const dictionaryKey = Symbol() as InjectionKey<
  ReadonlyRef<Record<string, Record<string, unknown>>>
>;
