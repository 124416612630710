import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cfb9849"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error text-negative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_icon, {
      name: _ctx.icon,
      size: "xl"
    }, null, 8, ["name"]),
    _createElementVNode("div", null, _toDisplayString(_ctx.message), 1)
  ]))
}