import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { ref, Ref, watch } from "vue";

import { createTarget } from "@/api/target/create";
import { fetchTarget } from "@/api/target/details";
import { TargetDetail, TargetType } from "@/api/target/typedefs";
import { updateTarget } from "@/api/target/update";
import { ReadonlyRef } from "@/lib/typing";
import TargetingModal from "@/views/debug/TargetingModal.vue";

export interface TargetingFormSuccessResponse {
  // TODO: Consider changing backend to return a full response
  //  (otherwise, you'll have to make second request to get changed data)
  targetId: string;
}

export interface TargetingFormErrorResponse {
  message: string;
}

export interface UseTargetingFormPayload {
  productId: ReadonlyRef<number>;
  productLineId: ReadonlyRef<number>;
  targetType: ReadonlyRef<TargetType>;
  targetId: ReadonlyRef<number | undefined> | undefined;
  onSuccess: (payload: TargetingFormSuccessResponse) => unknown;
  onError: (payload: TargetingFormErrorResponse) => unknown;
  isClone: ReadonlyRef<boolean | undefined> | undefined;
}

export function useTargetingForm(payload: UseTargetingFormPayload) {
  const isSubmitting = ref(false);
  const isLoading = ref(false);
  const targetValue: Ref<TargetDetail> = ref(buildEmptyTarget());
  const $q = useQuasar();

  function buildEmptyTarget(): TargetDetail {
    return {
      inactive: false,
      includePremiumUsers: false,
      query: "",
      targetId: "",
      targetName: "",
      targetTimeZone: getCurrentTimezoneValue(),
      segments: {},
    };
  }

  function onClone() {
    $q.dialog({
      component: TargetingModal,
      componentProps: {
        productId: payload.productId,
        targetType: payload.targetType,
        productLineId: payload.productLineId,
        targetId: payload.targetId,
        isClone: true,
      },
    });
  }

  function getCurrentTimezoneValue(): string {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = -offsetMinutes / 60;
    const roundedOffsetHours = Math.round(offsetHours);
    const clampedOffsetHours = Math.max(-12, Math.min(12, roundedOffsetHours));
    return clampedOffsetHours === 0 ? "utc_0" : `utc_${clampedOffsetHours}`;
  }

  async function fetchData() {
    if (payload.targetId?.value === undefined) {
      return;
    }
    try {
      isLoading.value = true;
      targetValue.value = await fetchTarget(
        payload.targetType.value,
        payload.productLineId.value,
        payload.targetId.value
      );
    } catch (error: unknown) {
      payload.onError({
        message: `Failed to fetch push message with ID: ${payload.targetId.value}`,
      });
    } finally {
      isLoading.value = false;
    }
  }

  watch(
    () => [payload.productLineId, payload.targetType, payload.targetId],
    fetchData,
    {
      immediate: undefined,
    }
  );

  async function onSubmit(): Promise<void> {
    isSubmitting.value = true;
    try {
      let targetId;
      if (payload.targetId?.value === undefined || payload.isClone?.value) {
        const response = await createTarget(
          payload.targetType.value,
          payload.productLineId.value,
          targetValue.value
        );
        targetId = response.targetId;
      } else {
        const response = await updateTarget(
          payload.targetType.value,
          payload.productLineId.value,
          payload.targetId.value,
          targetValue.value
        );
        targetId = response.targetId;
      }
      await payload.onSuccess({
        targetId: targetId,
      });
    } catch (error: unknown) {
      await payload.onError({
        message: String(error),
      });
    } finally {
      isSubmitting.value = false;
    }
  }

  return {
    isSubmitting,
    isLoading,
    targetValue,
    fetchData,
    onSubmit,
    onClone,
  };
}
