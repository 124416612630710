import script from "./TargetsForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./TargetsForm.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QForm from 'quasar/src/components/form/QForm.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QBtn,QCard,QCardSection,QInput,QSelect,QCheckbox,QIcon,QTooltip});
