import { computed } from "vue";

import { SegmentValue, TargetSegment } from "@/api/segments/typedefs";
import { FilterEventPayload, ScrollEventPayload } from "@/api/typedefs";
import { ReadonlyRef } from "@/lib/typing";

export interface UseVariableInputPayload {
  segment: ReadonlyRef<TargetSegment>;
  fetchOptions: (event: FilterEventPayload) => Promise<void>;
  fetchedValues: ReadonlyRef<SegmentValue[]>;
  fetchMoreOptions: (event: ScrollEventPayload) => Promise<void>;
  modelValue: ReadonlyRef<string | number | null>;
  dictionary?: ReadonlyRef<Record<string, Record<string, unknown>>>;
  increaseDisplayValue?: ReadonlyRef<number | undefined> | undefined;
}

export function useVariableInput(payload: UseVariableInputPayload) {
  const onFilterValues = async (event: FilterEventPayload) => {
    if (payload.segment?.value?.configuration.handler_type === "static_list") {
      return;
    }
    await payload.fetchOptions({
      value: event.value,
      onSuccess: event.onSuccess,
      onError: event.onError,
    });
  };

  const onVirtualScroll = async (event: ScrollEventPayload) => {
    if (payload.segment?.value?.configuration.handler_type === "static_list") {
      return;
    }
    await payload.fetchMoreOptions({
      to: event.to,
      ref: event.ref,
    });
  };

  const valueOptions = computed(() => {
    if (payload.segment?.value?.configuration.handler_type === "static_list") {
      if (typeof payload.segment.value?.configuration.options !== "object") {
        throw new Error("Unexpected type of options value");
      }
      return Object.values(payload.segment.value?.configuration.options).map(
        (value) => {
          return {
            id: value,
            text: value,
          };
        }
      );
    }

    if (payload.modelValue.value === undefined) {
      return [];
    }
    if (payload.fetchedValues.value.length === 0) {
      return [];
    }
    return payload.fetchedValues.value.map((value: SegmentValue) => {
      return {
        id: value.id,
        text: value.name,
      };
    });
  });

  const placeholderLength = computed(() => {
    if (payload.segment.value.configuration.placeholder) {
      return payload.segment.value.configuration.placeholder.length + "ch";
    }
    return "0ch";
  });

  const segmentDictionary = computed(() => {
    const segmentName = payload.segment.value?.name;
    return segmentName ? payload.dictionary?.value?.[segmentName] : undefined;
  });

  const displayValue = computed(() => {
    let value = payload.modelValue.value;
    if (
      payload.segment.value.configuration.type === "number" &&
      payload.increaseDisplayValue?.value !== undefined &&
      value !== null
    ) {
      value = Number(value) + payload.increaseDisplayValue.value;
    }
    return value;
  });

  const updateValue = (
    value: string | number | null
  ): string | number | null => {
    if (
      // Typeof wouldn't work because q-input returns a string.
      payload.segment.value.configuration.type === "number" &&
      payload.increaseDisplayValue?.value !== undefined &&
      value !== null
    ) {
      value = Number(value) - payload.increaseDisplayValue.value;
    }
    return value;
  };

  return {
    onFilterValues,
    onVirtualScroll,
    valueOptions,
    placeholderLength,
    segmentDictionary,
    displayValue,
    updateValue,
  };
}
