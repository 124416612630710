import { TargetSegment } from "@/api/segments/typedefs";
import { InputOperatorOptions } from "@/components/targetingpicker/utils/options";

export function getSegmentByName(
  segments: readonly TargetSegment[],
  name: string
): TargetSegment {
  const match = segments.filter((segment) => {
    if (name === "leanplum_id") {
      return segment.name === "bucket";
    }
    return segment.name === name;
  });

  if (match.length === 0) {
    throw new Error(`Segment ${name} not found`);
  }
  return match[0];
}

export function getDefaultInputOperatorForSegment(
  segment: TargetSegment
): string {
  const operatorType = segment.configuration.matching;

  // Bucket-type segments lack 'matching' config and can't use '==' operator.
  // This inconsistency may indicate a leaky abstraction or API oversight.
  // Also see `makeNode` function in `useSegmentPicker.ts`.
  if (operatorType === undefined && segment.configuration.type === "bucket") {
    return "bucket";
  } else if (operatorType === undefined) {
    return "==";
  }
  return Object.keys(InputOperatorOptions[operatorType])[0];
}
