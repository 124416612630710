import * as t from "io-ts";

export enum ValidationStatus {
  VALIDATING = "VALIDATING",
  VALIDATED = "VALIDATED",
  NOT_VALIDATED = "NOT_VALIDATED",
}

export const ValidationStatusGuard = t.keyof({
  [ValidationStatus.VALIDATED]: null,
  [ValidationStatus.VALIDATING]: null,
  [ValidationStatus.NOT_VALIDATED]: null,
});

// TODO(PNS-2880): Eventually drop TargetUnified and use Target instead
export const TargetUnifiedGuard = t.type({
  id: t.number,
  name: t.string,
  status: t.union([ValidationStatusGuard, t.null]),
  affectedUsers: t.union([t.number, t.null]),
  isRuntime: t.boolean,
});

export type TargetUnified = t.TypeOf<typeof TargetUnifiedGuard>;

export const TargetTypeGuard = t.union([
  t.literal("pushnotifications"),
  t.literal("inapps"),
]);
export type TargetType = t.TypeOf<typeof TargetTypeGuard>;

export const TargetGuard = t.type({
  id: t.number,
  name: t.string,
  targetTimezone: t.string,
  createdAt: t.string,
  modifiedAt: t.string,
  createdBy: t.string,
  modifiedBy: t.union([t.string, t.null]),
  affectedUsers: t.union([t.number, t.null]),
  inactive: t.boolean,
});
export type Target = t.TypeOf<typeof TargetGuard>;

export const TargetInAppGuard = t.intersection([
  TargetGuard,
  t.type({
    isRuntime: t.boolean,
  }),
]);
export type TargetInApp = t.TypeOf<typeof TargetInAppGuard>;

export const RawTargetResponseGuard = t.array(t.record(t.string, t.unknown));
export type RawTargetResponse = t.TypeOf<typeof RawTargetResponseGuard>;

export const TargetsFiltersGuard = t.type({
  name: t.union([t.string, t.undefined]),
  id: t.union([t.string, t.undefined]),
  createdBy: t.union([t.string, t.undefined]),
});
export type TargetFilters = t.TypeOf<typeof TargetsFiltersGuard>;

export const TargetDetailGuard = t.intersection([
  t.type({
    inactive: t.boolean,
    includePremiumUsers: t.boolean,
    query: t.string,
    targetId: t.string,
    targetName: t.string,
    targetTimeZone: t.string,
    segments: t.unknown, // Let the parser component deal with that.
  }),
  t.partial({
    isRuntime: t.boolean,
    dictionary: t.record(t.string, t.record(t.string, t.unknown)),
  }),
]);
export type TargetDetail = t.TypeOf<typeof TargetDetailGuard>;
